@font-face {
  font-family: RobotoLight;
  src: url('./fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBlond;
  src: url('./fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: MonseratBold;
  src: url('./fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MonseratBlack;
  src: url('./fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: HelveticaBold;
  src: url('./fonts/Helvetica-Bold.ttf');
}
@font-face {
  font-family: HelveticaRegular;
  src: url('./fonts/Helvetica.ttf');
}
@font-face {
  font-family: CodeProBold;
  src: url('./fonts/Code\ Pro\ Bold\ LC.otf');
}
@font-face {
  font-family: CodePro;
  src: url('./fonts/Code\ Pro\ LC.otf');
}

html, body {
  background-color: #5F6668;
  font-family: 'HelveticaRegular', sans-serif !important;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  display: none !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.header{
  background-color: transparent!important;
  font-size: 15px;
}
.App {
  /*text-align: center;*/
}
.bg-header-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: transparent;
  transition-duration: 0.5s;
}
.bg-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #1A1A1A;
  /*background: rgb(26,26,26);
  background: linear-gradient(180deg, rgba(26,26,26,1) 0%, rgba(255,255,255,0) 100%);*/
  transition-duration: 0.5s;
  opacity: 0.9;
}
.service-container {
  padding-bottom: 200px;
}
.navbar-hidden {
  top: -100px !important;
  transition: all 0s, opacity 0.5s linear;
}
.navbar-show {
  top: 0 !important;
  transition: all 0s, opacity 0.5s linear;
}
.nav-link {
  color: #fff !important;
}
.font-heder{
  color: #fff !important;
  font-family: 'HelveticaBold', sans-serif !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.footer{
  background-color: #83B7BA;
  background-image: url(./img/contact.png);
  background-size: cover;
  background-position: center;
  color:#fff;
  border-radius: 0;
  text-align: left;
  padding: 35px;
}
.section-round {
  border-radius: 80px;
}
.section {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.paloma-icon{
  width: 50px;
}
.location-icon{
  width: 15px;
}
.footer-text-title{
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  font-family: 'CodeProRegular', sans-serif !important;
}
.footer-text-desc{
  font-family: 'HelveticaBold', sans-serif !important;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.footer-text-link{
  padding-top: 15px;
  font-family: 'HelveticaRegular', sans-serif !important;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.footer-text-privacy{
  font-family: 'HelveticaBold', sans-serif !important;
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.footer-gif{
  width: 100%;
}
.logo-wite-style {
  width: 150px;
}
.foo-contact-title {
  font-size: 13px;
  font-family: 'HelveticaBold', sans-serif;
}
.foo-contact-info {
  font-size: 12px;
  font-family: 'HelveticaRegular', sans-serif;
}
.footer-text-subtitle{
  font-size: 35px;
  font-weight: 200;
  color: #fff;
  font-family: 'MonseratBold',sans-serif !important;
}
.form-footer{
  background-color: transparent!important;
  color:#fff!important;
  border: 1px solid #fff;
  font-family: 'HelveticaRegular',sans-serif !important;
}
.form-footer::placeholder{
  color:#fff!important;
}
.button-footer{
  background-color: #5F898B!important;
  border-color: #5F898B!important;
  font: larger;
  width:120px ;
  color: #fff!important;
  font-family: 'HelveticaBold', sans-serif !important;
  font-weight: bold;
  border-radius: 15px !important;
}
.line-footer{
  border-bottom: solid white 1px;
}
.social-media{
  width: 50px; 
}
.banner-us{
  background-image: url('./img/bgnosotros.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  /*background-color: #000;*/
  background-color: transparent;
  padding-top: 50px;
  padding-bottom: 50px;
}
.stroke-title {
  position: relative;
  left: -50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}
.secundary-title {
  position: relative;
  left: 90px;
}
.titles-us{
font-size: 60px;
line-height: 60px;
text-align: center;
font-family: 'CodeProBold', sans-serif !important;
padding-top: 80px;
padding-bottom: 80px;
}
.mas-service {
  width: 40px;
  padding: 7px 10px 7px 0;
}
.home-subtitle-white {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.description-us{
 font-size: 16px;
 color: #70aed7;
 line-height: 21px;
 text-align: left;
 padding-left: 50px!important;
}
.line-us{
  border-left:6px solid  #83B7BA !important;
  border-radius: 1px;
}
.container-row {
  padding-top: 15px;
}
.containers-photos {
  padding: 15px;
  border-radius: 20px;
}
.containers-photos-left{
  border-top-left-radius: 35px!important;
  border-bottom-left-radius: 35px!important;
}
.imgs-containers {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0px;
}
.containers-photos-right{
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px!important;
  vertical-align: middle!important;
  align-content: center!important;
  padding-left: 20px !important;
  padding-right: 30px !important;
}
.text-title-book{
  font-family: 'MonseratBold',sans-serif !important;
  font-size: 30px;
  color: #fff;
  width: 100%;
  font-weight: 100;
  vertical-align: middle;
  border-bottom: 2px solid #fff !important;
}
.text-desc-book{
  font-size: 15px;
  font-family: 'Roboto',sans-serif !important;
  text-align: left;
  color: #fff;
}
.photos-stants{
  padding: 5px!important;
  float: right;
  margin-left: 0;
}
.back-ground-services{
  background-color: #000;
}
.bg-halo{
  background: #339E9C!important;
}
.bg-cuadra{
  background: #D66921;
}
.bg-teq{
  background: #d66921;
}
.bg-six{
  background: #e50001;
}
.bg-hugo{
  background: #2E2929;
}
.bg-free{
  background: #5f6cca;
}
.bg-xp{
  background: #b03d60;
}
.bg-hp{
  background: #9A212A;
}
.bg-pepsi{
  background: #0c59b1;
}
.bg-img-pepsi{
  background: #e4e4e4;
  border-radius: 25px;
  width: 100%;
}
.img-gallery {
  width: 100%;
}
.img-halo{
  width: 150%!important;
  height: 150%!important;
  padding: 10px;
}
.marquee-img{
  width: 60%;
  max-height: 150px;
  padding: 5px 5px;
}
.service-tab{
  position: relative;
  top: 5px;
  font-family: 'HelveticaRegular',sans-serif !important;
  font-size: 15px;
  padding-left: 0px;
  margin-left: 0px;
}
.home-services-title{
  position: relative;
  top: -8px;
  color:#fff;
  font-size: 25px;
  line-height: 12px;
  font-weight: 400;
  font-family: 'HelveticaBold',sans-serif !important;
  padding-left: 10px;
  cursor: pointer;
}
.l-purple {
  color: #AFB7FD;
}
.l-green {
  color: #72B1B3;
}
.l-yellow {
  color: #FFBA00;
}
.l-red {
  color: #D54369;
}
.l-blue {
  color: #2641DD;
}
.l-orange {
  color: #D95217;
}
.l-lemon {
  color: #1CBD59;
}
.accordion-container {
  padding-left: 10% !important;
}
.home-title-ment{
  color:#97DDAA;
  font-size: 60px;
  text-align: left;
  font-family: 'CodeProBold',sans-serif !important;
}
.home-white-proyect{
  color: #fff;
  font-size: 75px;
  text-align: center;
  font-family: 'CodeProBold',sans-serif !important;
  background-image: url(./img/title_back.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-bottom: 90px;
}
.img-circulo{
  width: 14px;
}
.margin-service{
  margin-left:0px;
}
.home-desc-section {
  font-family: 'RobotoLight',sans-serif;
  font-size: 20px;
  color: #fff;
}
.home-pos{
  background-color: transparent;
  color:#7b9baf;
  font-size: 15px;
  text-align: left;
}
.accordion-container {
  padding-left: 10% !important;
}
.accordion-conten{
  color: #fff!important;
  background-color: #000!important;
}
.accordion-button{
  color: #fff!important;
  background-color: #000!important;
  text-align: left;
  display: contents!important;
}
.accordion{
  border: 0px solid #000000;
}
.accordion-body{
  margin-left: 30px;
}
.accordion-button.collapsed::after {
  /*background: url('./img/paloma.png');
  background-size: 27px;
  background-repeat: no-repeat;
  height: 27px;
  width: 27px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    display: inline;*/
}
.accordion-button:not(.collapsed)::after  {
  /*background: url('./img/paloma.png')!important;
  background-repeat: no-repeat;
  background-size: 27px;
  height: 27px;
  width: 27px;
  float: left;
  text-align: left;
  order: -1; 
  margin-left: 0; 
  margin-right:0.5em; 
  display: inline;*/
}
.accordion-button:not(.collapsed)::before  {
  background: url('./img/paloma.png');
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    border: none;
}
.accordion-item{
  background-color: transparent !important;
  color: #fff!important;
  --bs-accordion-border-color: none;
}
.home-title-black{
  color:#000;
  font-size: 60px;
  text-align: left;
  font-family: 'CodeProBold',sans-serif !important;
}
.player-v {
  width: 100% !important;
  /*height: 739px !important;*/
  height: calc( 100vw / 1.778 ) !important;
}
.player-c {
  width: 100% !important;
  height: auto !important;
}
.carousel-card-padding {
  padding: 20px 80px;
}
.centered {
  display: none;
}
.img-line {
  width: 100%;
  border: 3px solid #fff;
  opacity: 1;
  border-radius: 20px;
}
.image-title {
  font-family: 'CodeProBold', sans-serif !important;
  font-size: 25px;
  color: #fff;
}
.image-desc {
  font-family: 'HelveticaRegular', sans-serif !important;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
}
.img-container:hover {}
.img-container:hover .react-photo-album--photo {
  webkit-filter: brightness(70%) blur(10px);
  filter: brightness(70%) blur(10px);
}
.img-container:hover .centered { 
  display: inline;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.padd-left {
  padding-left: 0 !important;
}
.rrss-icons {
  width: 35px;
}
.contact-container {
  width: 90%;
  border-radius: 70px;
  /*border: 5px solid #fff;*/
  padding: 50px;
}
.form-container {
  padding: 40px 50px;
  background-color: #9DC9CC;
  border-radius: 60px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

@media (max-width: 460px) {
  .player-v{
    width: auto !important;
    height: calc( 100vw / 1.778 ) !important;
    padding-right: 1px !important;
  }
  .accordion-button:not(.collapsed)::after{
    background-size: 27px !important;
    --bs-accordion-btn-icon-transform: rotate(-360deg) !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
  }
  .footer-text-title {
    font-size: 20px;
    line-height: 60px;
    text-align: center;
  }
  .footer-text-desc{
    padding-bottom: 15px;
  }
  .marquee-img{
    width: 50%;
    padding: 5px 5px;
  }
  .home-title-ment, .home-white-proyect{
    font-size: 50px;
    text-align: center;
  }
  .titles-us {
    font-size: 50px;
    text-align: center;
    padding: 25px 12px !important;
  }
  .carousel-card-padding {
    padding: 20px 20px;
  }
  .last-col {
    padding-top: 24px;
  }
  .line-us {
    border-left: 0px solid #8dd19e !important;
  }
  .description-us {
    text-align: center;
    padding: 0 30px!important;
  }
  .secundary-title, .stroke-title {
    left: 0;
  }
  .footer {
    padding: 10px;
  }
  .contact-container {
    padding: 10px;
    width: 100%;
  }
  .img-container:hover .centered {
    width: 95%;
  }
  .home-services-title{
    position: relative;
    top: 0px;
    font-size: 20px;
    line-height: 24px;
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .home-services-icon{
    position: relative;
    top: 2px;
    width: 25px;
  }
  .home-subtitle-white {
    font-size: 18px;
  }
}